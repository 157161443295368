import { type ModuleOptions } from "#shared/../module";
export const UiConfig: ModuleOptions = {
  "theme": {
    "app": {
      "theme": "agora-main",
      "name": "AgoraStore"
    },
    "slots": {
      "navigation": {
        "hover": "secondary",
        "active": "secondary"
      },
      "header": {
        "border": "secondary",
        "sellButton": {
          "background": "secondary",
          "text": "#000"
        }
      },
      "wizard": {
        "progressBar": "secondary"
      },
      "itemCard": {
        "bidButton": {
          "background": "secondary",
          "text": "#000"
        }
      }
    },
    "logo": {
      "small": "/themes/agora-main/logo-small.svg",
      "large": "/themes/agora-main/logo.svg",
      "favicon": "/themes/agora-main/favicon.ico"
    },
    "common": {
      "borderRadius": "4px"
    },
    "colors": {
      "primary": "#2B4D93",
      "secondary": "#FDBF62"
    }
  },
  "features": {
    "dataList": {
      "defaultPersistency": true
    },
    "accessKeys": {
      "CLIENT": {
        "PURCHASE": {
          "BID": {
            "CREATE": "CLIENT_PURCHASE_BID_CREATE"
          }
        },
        "ORGANISATION": {
          "ADDRESS": {
            "MANAGE": "CLIENT_ORGANISATION_ADDRESS_MANAGE"
          },
          "CONTRACT": {
            "SIGN": "CLIENT_ORGANISATION_CONTRACT_SIGN"
          },
          "ORGANISATION": {
            "MANAGE": "CLIENT_ORGANISATION_ORGANISATION_MANAGE"
          },
          "STATS": {
            "EXTRACT": "CLIENT_ORGANISATION_STATS_EXTRACT"
          },
          "USER": {
            "DELETE": "CLIENT_ORGANISATION_USER_DELETE",
            "MANAGE": "CLIENT_ORGANISATION_USER_MANAGE"
          }
        },
        "SEANCE": {
          "SEANCE": {
            "PUBLISH": "CLIENT_SEANCE_SEANCE_PUBLISH",
            "DELETE": "CLIENT_SEANCE_SEANCE_DELETE",
            "MANAGE": "CLIENT_SEANCE_SEANCE_MANAGE"
          }
        },
        "SALES": {
          "ITEM": {
            "ASSIGN": "CLIENT_SALES_ITEM_ASSIGN"
          },
          "ITEM_MANUAL": {
            "ASSIGN": "CLIENT_SALES_ITEM_MANUAL_ASSIGN"
          },
          "COMMISSION_INVOICE": {
            "GENERATE": "CLIENT_SALES_COMMISSION_INVOICE_GENERATE",
            "MANAGE": "CLIENT_SALES_COMMISSION_INVOICE_MANAGE"
          },
          "BID": {
            "ASSIGN": "CLIENT_SALES_BID_ASSIGN"
          },
          "SALES_INVOICE": {
            "GENERATE": "CLIENT_SALES_SALES_INVOICE_GENERATE",
            "MANAGE": "CLIENT_SALES_SALES_INVOICE_MANAGE",
            "VALIDATE": "CLIENT_SALES_SALES_INVOICE_VALIDATE"
          },
          "SLIPS": {
            "GENERATE": "CLIENT_SALES_SLIPS_GENERATE"
          }
        },
        "EVENT": {
          "VISIT": {
            "DELETE": "CLIENT_EVENT_VISIT_DELETE",
            "MANAGE": "CLIENT_EVENT_VISIT_MANAGE"
          },
          "PICK_UP": {
            "DELETE": "CLIENT_EVENT_PICK_UP_DELETE",
            "MANAGE": "CLIENT_EVENT_PICK_UP_MANAGE"
          },
          "SALES_INVOICE": {
            "MANAGE": "CLIENT_EVENT_SALES_INVOICE_MANAGE"
          }
        },
        "ITEM": {
          "ITEM": {
            "AUTHORISATION": "CLIENT_ITEM_ITEM_AUTHORISATION",
            "DELETE": "CLIENT_ITEM_ITEM_DELETE",
            "MANAGE": "CLIENT_ITEM_ITEM_MANAGE",
            "PUBLISH": "CLIENT_ITEM_ITEM_PUBLISH",
            "REMOVE": "CLIENT_ITEM_ITEM_REMOVE"
          }
        }
      }
    },
    "date": {
      "displayFormat": {
        "en": {
          "date": "PP",
          "datetime": "PPp",
          "daterange": "PP",
          "datetimerange": "PPp"
        }
      }
    }
  }
};