import type { RouteLocationRaw } from '#vue-router'
import type { ApiResponse } from '#types/system/api'

export function appLink(to: Exclude<RouteLocationRaw, string>) {
  return to
}

export function getLocalizedValue<
  T extends { language: string } & { [key: string]: unknown },
  Key extends keyof T | undefined = undefined
>(translations: T[], key?: Key) {
  const { $i18n } = useNuxtApp()
  const translation = translations.find(t => t.language === $i18n.locale.value)
  return (key ? translation?.[key] : translation) as Key extends undefined ? T : T[Key]
}

export function slugify(text = '', limit = 80) {
  const cleaned = text.toLowerCase().replace(/[^\w\s]/g, '')
  const hyphenated = cleaned.replace(/\s+/g, '-')
  if (hyphenated.length <= limit) return hyphenated
  const truncated = hyphenated.slice(0, limit)
  const lastHyphenIndex = truncated.lastIndexOf('-')
  return lastHyphenIndex === -1 ? truncated : truncated.slice(0, lastHyphenIndex)
}

export function getBaseResourceUrl(resourceType: 'documents' | 'pictures' | 'videos') {
  const { $i18n, $cdn } = useNuxtApp()
  const cdnUrl = $cdn.getUrl()
  return `${cdnUrl}/${$i18n.locale.value}/resources/${resourceType}`
}

export function isExternalURL(url: string): boolean {
  if (url.startsWith('/'))
    return false

  try {
    const urlObject = new URL(url)

    return urlObject.hostname !== window.location.hostname
  } catch {
    return false
  }
}

export function isInternalPath(path: string): boolean {
  return !isExternalURL(path)
}

export function buildRedirectUrlWithToken(token: string, redirectUrl: string) {
  const { $i18n } = useNuxtApp()
  try {
    const url = new URL(decodeURIComponent(redirectUrl))
    url.searchParams.set('token', token)
    if (!url.searchParams.has('locale'))
      url.searchParams.set('locale', $i18n.locale.value)

    const finalUrl = url.toString()
    return { url: finalUrl, external: isExternalURL(finalUrl) }
  } catch {
    return { url: '/profile/information', external: false }
  }
}

export async function withErrorHandling<Data>(
  apiCall: () => Promise<Data>
): Promise<ApiResponse<Data>> {
  try {
    const data = await withoutGlobalError(apiCall)
    return {
      success: true,
      data
    }
  } catch (error) {
    return {
      success: false,
      error: error
    }
  }
}

export function match<T extends string, N extends string>(value: T, ...compareTo: N[]) {
  return compareTo.some(val => (val as string) === (value as string)) as T extends N ? true : false
}
