import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3'

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig()

  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: config.public.recaptcha.v3SiteKey,
    loaderOptions: { autoHideBadge: true }
  })

  const { executeRecaptcha, recaptchaLoaded } = useReCaptcha() ?? {}
  await recaptchaLoaded?.()
  return {
    provide: {
      recaptcha: executeRecaptcha!
    }
  }
})
