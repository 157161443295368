export default defineNuxtRouteMiddleware(async (to) => {
  console.log('MIDDLEWARE::REDIRECT', to.fullPath)
  const userStore = useUserStore()
  await userStore.waitUntilAuthLoaded()

  await nextTick()
  if (!userStore.beaconToken) return

  const redirectUrl = to?.query?.redirect?.toString()
  if (!redirectUrl) return

  const { url, external } = buildRedirectUrlWithToken(userStore.beaconToken, redirectUrl)
  if (external) return navigateTo(url, { external })
})
