export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const activeCdnId = ref(0)
  const cdnUrls = ref<string[]>([])

  function loadCdn(urls: string[]) {
    cdnUrls.value = [...new Set(urls)]
  }

  function parseCdnString(urls: string) {
    return urls.split(',').map(url => url.trim())
  }

  function getUrl() {
    if (!cdnUrls.value.length) throw new Error('No CDN URLs loaded')
    const url = cdnUrls.value[activeCdnId.value]

    activeCdnId.value = (activeCdnId.value + 1) % cdnUrls.value.length

    return url
  }

  if (config.public.cdnUrls)
    loadCdn(parseCdnString(config.public.cdnUrls))

  return {
    provide: {
      cdn: {
        loadCdn,
        getUrl
      }
    }
  }
})
