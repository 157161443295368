<template>
  <div class="bg-white w-screen h-screen flex flex-col">
    <!-- Header -->
    <header class="flex justify-between items-center p-4 border-b">
      <NSkeleton
        class="w-32 h-8"
        :sharp="false"
      />
      <div class="flex items-center space-x-4">
        <NSkeleton
          class="w-20 h-8"
          :sharp="false"
        />
        <NSkeleton
          class="w-10 h-10 rounded-full"
          :sharp="false"
        />
      </div>
    </header>

    <div class="flex flex-1 overflow-hidden">
      <!-- Sidebar -->
      <aside class="w-64 p-4 border-r overflow-y-auto">
        <NSkeleton
          class="w-full h-6 mb-4"
          :sharp="false"
        />
        <NSkeleton
          class="w-3/4 h-4 mb-2"
          :sharp="false"
          :repeat="5"
        />
        <NSkeleton
          class="w-full h-6 mt-6 mb-4"
          :sharp="false"
        />
        <NSkeleton
          class="w-3/4 h-4 mb-2"
          :sharp="false"
          :repeat="5"
        />
      </aside>

      <!-- Main content -->
      <main class="flex-1 p-6 overflow-y-auto">
        <div class="mb-6">
          <NSkeleton
            class="w-1/4 h-8 mb-4"
            :sharp="false"
          />
          <NSkeleton
            class="w-full h-4 mb-2"
            :sharp="false"
            :repeat="3"
          />
        </div>

        <!-- Dashboard content placeholder -->
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div
            v-for="i in 6"
            :key="i"
            class="border rounded-lg p-4"
          >
            <NSkeleton
              class="w-full h-32 mb-4"
              :sharp="false"
            />
            <NSkeleton
              class="w-3/4 h-4 mb-2"
              :sharp="false"
              :repeat="3"
            />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
