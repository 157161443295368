function loader<T>(orgId: ComputedRef<string>, handler: () => Promise<T[]>): Promise<T[]> {
  if (!orgId.value) return Promise.resolve([])
  return withoutGlobalError(handler)
}

export const useDataStore = defineStore('data-store', () => {
  const { $api } = useNuxtApp()
  const userStore = useUserStore()
  const orgId = computed(() => userStore.organisationShortId)
  const currentShopId = ref<string>()

  const _seances = useAsyncState(() => loader(orgId, () => $api.seances.getSeances({ seanceShortIds: [orgId.value] })), { watch: [orgId] })
  const _shops = useAsyncState(() => $api.shops.getShops())

  const seances = computed(() => _seances.pending.value ? [] : _seances.data.value ?? [])
  const shops = computed(() => _shops.pending.value ? [] : _shops.data.value ?? [])
  const currentShop = computed(() => shops.value?.find(shop => shop.shopShortId === currentShopId.value))
  const shopConfig = computed(() => APP_SHOPS.find(shop => shop.shopShortId === currentShopId.value))

  function setCurrentShopId(shopShortId: string) {
    currentShopId.value = shopShortId
  }

  return { seances, shops, currentShopId, currentShop, shopConfig, setCurrentShopId }
})
