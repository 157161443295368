import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _1_45redirect_45global from "/app/app/middleware/1.redirect.global.ts";
import _2_45auth_45global from "/app/app/middleware/2.auth.global.ts";
import route_45transition_45global from "/app/app/middleware/route-transition.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _1_45redirect_45global,
  _2_45auth_45global,
  route_45transition_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}