import type { AccessKeyPath, AccessKeysTree } from '#access-manager'
import type { DeepPartial } from '#types/system/utils'

export const passwordStrengthRegex
  = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$&+,:;=?@#|'<>.^*()%!-]).{8,}$/

export function hasAccess(keys: Array<AccessKeyPath>) {
  const userStore = useUserStore()
  if (!userStore.enableAccessControl) return true
  const obj = keys.map(key => getObjectProperty(userStore.accessKeyTree, key))
  return obj.some(Boolean)
}

export function createAccessTree(keys: string[]): DeepPartial<AccessKeysTree> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tree: Record<string, any> = {}

  for (const key of keys) {
    if (key === '...' || !key) continue
    const parts = key.split('_')
    let current = tree
    for (let i = 0; i < parts.length; i++) {
      const part = parts[i]
      if (i === parts.length - 1) current[part] = true
      else {
        current[part] = current[part] || {}
        current = current[part]
      }
    }
  }

  return tree
}
