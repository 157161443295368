<script setup lang="ts">
const { $i18n } = useNuxtApp()
const dataStore = useDataStore()
const userStore = useUserStore()
const appUi = useAppUi()

const pageHead = computed(() => ({
  title: [$i18n.t('navigation.personalSpace'), dataStore.currentShop?.name].filter(Boolean).join(' - ')
}))

useHead(pageHead)
watch(() => appUi.themeConfig.colors.primary, color => setMarkerTheme(color))

function setMarkerTheme(color) {
  const iframe = document.querySelector<HTMLIFrameElement>('iframe#feedback-button[data-marker-frame]')
  if (!iframe || !iframe.contentWindow) return false

  try {
    const iframeDoc = iframe.contentWindow.document
    let styleElement = iframeDoc.getElementById('marker-theme-style')

    if (!styleElement) {
      styleElement = document.createElement('style')
      styleElement.id = 'marker-theme-style'
      iframeDoc.head.appendChild(styleElement)
    }

    styleElement.textContent = `.feedback-btn{background-color:${color}!important}`
    return true
  } catch (error) {
    console.error('Error setting marker theme:', error)
    return false
  }
}
</script>

<template>
  <AppRoot>
    <AppSkeleton
      v-if="
        (!userStore.user || !userStore.activeOrganisation)
          && userStore.beaconToken
          && $route.name !== 'healthcheck'
      "
    />
    <NuxtLayout v-else>
      <NuxtPage :transition="false" />
    </NuxtLayout>
  </AppRoot>
</template>
