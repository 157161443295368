export default defineNuxtPlugin((app) => {
  app.hook('app:created', () => {
    const { $i18n } = useNuxtApp()

    const locale = localStorage.getItem('locale')
    if (locale) $i18n.locale.value = locale

    watch(
      () => ($i18n.locale as unknown as Ref<string>).value,
      locale => localStorage.setItem('locale', locale)
    )
  })
})
