export default defineNuxtPlugin(() => {
  const router = useRouter()

  ROUTER_REDIRECTS.forEach((redirect) => {
    router.addRoute({
      path: redirect.from,
      redirect: (from) => {
        const callbackUrl = (from.query.callback?.toString())?.split('&')[0]
        const { callback: _, ...remainingQuery } = from.query

        const query = callbackUrl
          ? {
              [redirect.callback === 'immediate' ? 'redirect' : 'callback']: callbackUrl,
              ...remainingQuery
            }
          : remainingQuery

        return typeof redirect.to === 'string'
          ? { path: redirect.to, query }
          : { ...redirect.to, query: { ...(redirect.to?.query ?? {}), ...query } }
      }
    })
  })
})
