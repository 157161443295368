export const APP_SHOPS = defineShops([
  {
    shopShortId: 'JCXPNh2rmTBmN2jTK9AOE',
    theme: 'agora-main',
    match: hostname => hostname.includes('test-customer.agorastore.fr'),
    links: {
      putToSale: ''
    }
  },
  {
    shopShortId: 'luA0tu56n6J7WXPYiRo7M',
    theme: 'auctelia',
    match: hostname => hostname.includes('test-customer.auctelia.com'),
    links: {
      putToSale: 'self'
    }
  },
  {
    shopShortId: '6VNRqeZmwXxHFa6WWK2Ks',
    theme: 'agora-main',
    match: hostname => hostname.includes('preview-customer.agorastore.fr'),
    links: {
      putToSale: ''
    }
  },
  {
    shopShortId: '6VNRqeZmwXxHFa6WWK2Kr',
    theme: 'auctelia',
    match: hostname => hostname.includes('preview-customer.auctelia.com'),
    links: {
      putToSale: 'self'
    }
  },
  {
    shopShortId: 'AgorastoreShopShortId',
    theme: 'agora-main',
    match: hostname => hostname.includes('customer.agorastore.fr'),
    links: {
      putToSale: ''
    }
  },
  {
    shopShortId: 'AucteliaShopShortId',
    theme: 'auctelia',
    match: hostname => hostname.includes('customer.auctelia.com'),
    links: {
      putToSale: 'self'
    }
  },
  {
    shopShortId: 'JCXPNh2rmTBmN2jTK9AOE',
    theme: 'agora-main',
    fallback: true,
    links: {
      putToSale: ''
    }
  }
])
