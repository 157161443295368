
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_9321KQCtI9YwMeta } from "/app/app/pages/[...slug].vue?macro=true";
import { default as loginJk0nDs6mH8Meta } from "/app/app/pages/auth/login.vue?macro=true";
import { default as logoutQQ1ZWE6bQlMeta } from "/app/app/pages/auth/logout.vue?macro=true";
import { default as completedTf3DqgzXqpMeta } from "/app/app/pages/auth/password/completed.vue?macro=true";
import { default as forgotEg9Jij4i6vMeta } from "/app/app/pages/auth/password/forgot.vue?macro=true";
import { default as resetmXryHkpI39Meta } from "/app/app/pages/auth/password/reset.vue?macro=true";
import { default as completedn6toSEspq3Meta } from "/app/app/pages/auth/register/completed.vue?macro=true";
import { default as indexkme81FvJAPMeta } from "/app/app/pages/auth/register/index.vue?macro=true";
import { default as validateFg7dUKuA91Meta } from "/app/app/pages/auth/validate.vue?macro=true";
import { default as purchasessZnprQXcCeMeta } from "/app/app/pages/buyer/invoices/purchases.vue?macro=true";
import { default as indexrfFf4ZMmYiMeta } from "/app/app/pages/buyer/items/[itemShortId]/index.vue?macro=true";
import { default as index0ocTKJhbaFMeta } from "/app/app/pages/buyer/items/index.vue?macro=true";
import { default as _91itemShortId_93EXNCfwoa3wMeta } from "/app/app/pages/buyer/questions/[itemShortId].vue?macro=true";
import { default as indexoHg0dFRYUoMeta } from "/app/app/pages/buyer/questions/index.vue?macro=true";
import { default as indexS6NxTGtnZUMeta } from "/app/app/pages/buyer/saved-searches/index.vue?macro=true";
import { default as watch_45listsCPfeV2uImsMeta } from "/app/app/pages/buyer/watch-lists.vue?macro=true";
import { default as dashboardgCXiDF9kLpMeta } from "/app/app/pages/dashboard.vue?macro=true";
import { default as healthcheckwC6FyzllAxMeta } from "/app/app/pages/healthcheck.vue?macro=true";
import { default as _91addressId_936PJj5noRcbMeta } from "/app/app/pages/organisation/addresses/[addressId].vue?macro=true";
import { default as createcMSkg50mpCMeta } from "/app/app/pages/organisation/addresses/create.vue?macro=true";
import { default as index2A2GayHwivMeta } from "/app/app/pages/organisation/addresses/index.vue?macro=true";
import { default as contractszFuJQrUtrBMeta } from "/app/app/pages/organisation/contracts.vue?macro=true";
import { default as documentsdQQ7BsgXXhMeta } from "/app/app/pages/organisation/documents.vue?macro=true";
import { default as informationP8d4JkGIz3Meta } from "/app/app/pages/organisation/information.vue?macro=true";
import { default as _91userId_93BcJQYayqsdMeta } from "/app/app/pages/organisation/users/[userId].vue?macro=true";
import { default as indexFdfk92v80iMeta } from "/app/app/pages/organisation/users/index.vue?macro=true";
import { default as _91addressId_93tbi3KU2JDZMeta } from "/app/app/pages/profile/addresses/[addressId].vue?macro=true";
import { default as createkRvI5fTnkEMeta } from "/app/app/pages/profile/addresses/create.vue?macro=true";
import { default as indexlgeYMYlb07Meta } from "/app/app/pages/profile/addresses/index.vue?macro=true";
import { default as documentsJRSw3ab8aYMeta } from "/app/app/pages/profile/documents.vue?macro=true";
import { default as informationbI95FxVPBqMeta } from "/app/app/pages/profile/information.vue?macro=true";
import { default as notificationsUu4Camamu2Meta } from "/app/app/pages/profile/notifications.vue?macro=true";
import { default as onboarding1SxIAfCuVjMeta } from "/app/app/pages/profile/onboarding.vue?macro=true";
import { default as commissions7nPLh20KucMeta } from "/app/app/pages/seller/invoices/commissions.vue?macro=true";
import { default as sales8iXXIcXswQMeta } from "/app/app/pages/seller/invoices/sales.vue?macro=true";
import { default as indexQ1Tt5GfnsjMeta } from "/app/app/pages/seller/items/[itemShortId]/index.vue?macro=true";
import { default as indexeruYI1wBRhMeta } from "/app/app/pages/seller/items/index.vue?macro=true";
import { default as _91itemShortId_93eEMGdaQKWnMeta } from "/app/app/pages/seller/questions/[itemShortId].vue?macro=true";
import { default as indexDtek2bnWbpMeta } from "/app/app/pages/seller/questions/index.vue?macro=true";
import { default as indexvyYZ70Xe1iMeta } from "/app/app/pages/seller/sale-validations/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/app/app/pages/[...slug].vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginJk0nDs6mH8Meta || {},
    component: () => import("/app/app/pages/auth/login.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    meta: logoutQQ1ZWE6bQlMeta || {},
    component: () => import("/app/app/pages/auth/logout.vue")
  },
  {
    name: "auth-password-completed",
    path: "/auth/password/completed",
    meta: completedTf3DqgzXqpMeta || {},
    component: () => import("/app/app/pages/auth/password/completed.vue")
  },
  {
    name: "auth-password-forgot",
    path: "/auth/password/forgot",
    meta: forgotEg9Jij4i6vMeta || {},
    component: () => import("/app/app/pages/auth/password/forgot.vue")
  },
  {
    name: "auth-password-reset",
    path: "/auth/password/reset",
    meta: resetmXryHkpI39Meta || {},
    component: () => import("/app/app/pages/auth/password/reset.vue")
  },
  {
    name: "auth-register-completed",
    path: "/auth/register/completed",
    meta: completedn6toSEspq3Meta || {},
    component: () => import("/app/app/pages/auth/register/completed.vue")
  },
  {
    name: "auth-register",
    path: "/auth/register",
    meta: indexkme81FvJAPMeta || {},
    component: () => import("/app/app/pages/auth/register/index.vue")
  },
  {
    name: "auth-validate",
    path: "/auth/validate",
    meta: validateFg7dUKuA91Meta || {},
    component: () => import("/app/app/pages/auth/validate.vue")
  },
  {
    name: "buyer-invoices-purchases",
    path: "/buyer/invoices/purchases",
    meta: purchasessZnprQXcCeMeta || {},
    component: () => import("/app/app/pages/buyer/invoices/purchases.vue")
  },
  {
    name: "buyer-items-itemShortId",
    path: "/buyer/items/:itemShortId()",
    component: () => import("/app/app/pages/buyer/items/[itemShortId]/index.vue")
  },
  {
    name: "buyer-items",
    path: "/buyer/items",
    meta: index0ocTKJhbaFMeta || {},
    component: () => import("/app/app/pages/buyer/items/index.vue")
  },
  {
    name: "buyer-questions-itemShortId",
    path: "/buyer/questions/:itemShortId()",
    meta: _91itemShortId_93EXNCfwoa3wMeta || {},
    component: () => import("/app/app/pages/buyer/questions/[itemShortId].vue")
  },
  {
    name: "buyer-questions",
    path: "/buyer/questions",
    meta: indexoHg0dFRYUoMeta || {},
    component: () => import("/app/app/pages/buyer/questions/index.vue")
  },
  {
    name: "buyer-saved-searches",
    path: "/buyer/saved-searches",
    meta: indexS6NxTGtnZUMeta || {},
    component: () => import("/app/app/pages/buyer/saved-searches/index.vue")
  },
  {
    name: "buyer-watch-lists",
    path: "/buyer/watch-lists",
    meta: watch_45listsCPfeV2uImsMeta || {},
    component: () => import("/app/app/pages/buyer/watch-lists.vue")
  },
  {
    name: "dashboard",
    path: "/",
    meta: dashboardgCXiDF9kLpMeta || {},
    component: () => import("/app/app/pages/dashboard.vue")
  },
  {
    name: "healthcheck",
    path: "/healthcheck",
    meta: healthcheckwC6FyzllAxMeta || {},
    component: () => import("/app/app/pages/healthcheck.vue")
  },
  {
    name: "organisation-addresses-addressId",
    path: "/organisation/addresses/:addressId()",
    meta: _91addressId_936PJj5noRcbMeta || {},
    component: () => import("/app/app/pages/organisation/addresses/[addressId].vue")
  },
  {
    name: "organisation-addresses-create",
    path: "/organisation/addresses/create",
    meta: createcMSkg50mpCMeta || {},
    component: () => import("/app/app/pages/organisation/addresses/create.vue")
  },
  {
    name: "organisation-addresses",
    path: "/organisation/addresses",
    meta: index2A2GayHwivMeta || {},
    component: () => import("/app/app/pages/organisation/addresses/index.vue")
  },
  {
    name: "organisation-contracts",
    path: "/organisation/contracts",
    meta: contractszFuJQrUtrBMeta || {},
    component: () => import("/app/app/pages/organisation/contracts.vue")
  },
  {
    name: "organisation-documents",
    path: "/organisation/documents",
    meta: documentsdQQ7BsgXXhMeta || {},
    component: () => import("/app/app/pages/organisation/documents.vue")
  },
  {
    name: "organisation-information",
    path: "/organisation/information",
    meta: informationP8d4JkGIz3Meta || {},
    component: () => import("/app/app/pages/organisation/information.vue")
  },
  {
    name: "organisation-users-userId",
    path: "/organisation/users/:userId()",
    meta: _91userId_93BcJQYayqsdMeta || {},
    component: () => import("/app/app/pages/organisation/users/[userId].vue")
  },
  {
    name: "organisation-users",
    path: "/organisation/users",
    meta: indexFdfk92v80iMeta || {},
    component: () => import("/app/app/pages/organisation/users/index.vue")
  },
  {
    name: "profile-addresses-addressId",
    path: "/profile/addresses/:addressId()",
    meta: _91addressId_93tbi3KU2JDZMeta || {},
    component: () => import("/app/app/pages/profile/addresses/[addressId].vue")
  },
  {
    name: "profile-addresses-create",
    path: "/profile/addresses/create",
    meta: createkRvI5fTnkEMeta || {},
    component: () => import("/app/app/pages/profile/addresses/create.vue")
  },
  {
    name: "profile-addresses",
    path: "/profile/addresses",
    meta: indexlgeYMYlb07Meta || {},
    component: () => import("/app/app/pages/profile/addresses/index.vue")
  },
  {
    name: "profile-documents",
    path: "/profile/documents",
    meta: documentsJRSw3ab8aYMeta || {},
    component: () => import("/app/app/pages/profile/documents.vue")
  },
  {
    name: "profile-information",
    path: "/profile/information",
    meta: informationbI95FxVPBqMeta || {},
    component: () => import("/app/app/pages/profile/information.vue")
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: notificationsUu4Camamu2Meta || {},
    component: () => import("/app/app/pages/profile/notifications.vue")
  },
  {
    name: "profile-onboarding",
    path: "/profile/onboarding",
    meta: onboarding1SxIAfCuVjMeta || {},
    component: () => import("/app/app/pages/profile/onboarding.vue")
  },
  {
    name: "seller-invoices-commissions",
    path: "/seller/invoices/commissions",
    meta: commissions7nPLh20KucMeta || {},
    component: () => import("/app/app/pages/seller/invoices/commissions.vue")
  },
  {
    name: "seller-invoices-sales",
    path: "/seller/invoices/sales",
    meta: sales8iXXIcXswQMeta || {},
    component: () => import("/app/app/pages/seller/invoices/sales.vue")
  },
  {
    name: "seller-items-itemShortId",
    path: "/seller/items/:itemShortId()",
    component: () => import("/app/app/pages/seller/items/[itemShortId]/index.vue")
  },
  {
    name: "seller-items",
    path: "/seller/items",
    meta: indexeruYI1wBRhMeta || {},
    component: () => import("/app/app/pages/seller/items/index.vue")
  },
  {
    name: "seller-questions-itemShortId",
    path: "/seller/questions/:itemShortId()",
    meta: _91itemShortId_93eEMGdaQKWnMeta || {},
    component: () => import("/app/app/pages/seller/questions/[itemShortId].vue")
  },
  {
    name: "seller-questions",
    path: "/seller/questions",
    meta: indexDtek2bnWbpMeta || {},
    component: () => import("/app/app/pages/seller/questions/index.vue")
  },
  {
    name: "seller-sale-validations",
    path: "/seller/sale-validations",
    meta: indexvyYZ70Xe1iMeta || {},
    component: () => import("/app/app/pages/seller/sale-validations/index.vue")
  }
]