import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((app) => {
  const config = useRuntimeConfig()
  const router = useRouter()

  Sentry.init({
    Vue: app.vueApp,
    dsn: config.public.sentry.dsn,
    integrations: [
      Sentry.httpContextIntegration(),
      Sentry.httpContextIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration({ router })
    ],
    sendClientReports: true,
    enabled: config.public.env !== 'dev',
    environment: config.public.env,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.1
  })
})
