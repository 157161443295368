import * as _ from 'radash'
import type { ItemStatus, ItemTranslation } from '#types/entities/item'
import type { Item } from '#types/entities/search'
import { ItemStatuses } from '#api-client'

export function getLocalizedItem(translations: ItemTranslation[]) {
  const { $i18n } = useNuxtApp()
  return translations.find(t => t.language === $i18n.locale.value)
}

export function getItemThumbnail(
  item: Pick<Item, 'relatedResources' | 'translations' | 'itemShortId' | 'seanceShortId'>
): { src: string, title: string } | null {
  const pictures = item?.relatedResources?.pictures ?? []
  const thumbnail = pictures.find(p => p.thumbnail) ?? pictures[0]

  if (!thumbnail || !thumbnail.resourceReferenceId)
    return null

  return {
    src: prepareItemPictureUrl({ resourceReferenceId: thumbnail.resourceReferenceId, item, size: 'M' }),
    title: thumbnail.title
  }
}

export function canPerformOnItem(action: 'show' | 'cancel' | 'delete' | 'putOnSale' | 'update' | 'viewInvoice', status: ItemStatus) {
  switch (action) {
    case 'show':
      return !includes(status, [
        ItemStatuses.TO_CHECK,
        ItemStatuses.TO_CHECK_BO,
        ItemStatuses.TO_CHECK_EP,
        ItemStatuses.TO_MODIFY,
        ItemStatuses.REMOVED,
        ItemStatuses.REFUSED,
        ItemStatuses.NOT_ASSIGNED,
        ItemStatuses.NOT_PAID,
        ItemStatuses.REIMBURSED
      ])
    case 'cancel':
      return includes(status, [ItemStatuses.TO_CHECK, ItemStatuses.TO_MODIFY])
    case 'delete':
      return includes(status, [ItemStatuses.TO_CHECK, ItemStatuses.TO_MODIFY])
    case 'putOnSale':
      return includes(status, [
        ItemStatuses.UNSOLD,
        ItemStatuses.REMOVED,
        ItemStatuses.NOT_ASSIGNED,
        ItemStatuses.REIMBURSED,
        ItemStatuses.NOT_PAID
      ])
    case 'update':
      return includes(status, [ItemStatuses.TO_CHECK, ItemStatuses.TO_MODIFY])
    case 'viewInvoice':
      return includes(status, [ItemStatuses.INVOICED, ItemStatuses.PAID, ItemStatuses.CONFIRMED, ItemStatuses.COMMISSIONED, ItemStatuses.EXECUTED, ItemStatuses.SETTLED])
    default:
      return false
  }
}

export function getItemSourceType(item: Pick<Item, 'translations' | 'itemShortId' | 'seanceShortId'>) {
  const description = getLocalizedValue(item.translations)
  if (!description || !description.name) return null
  return {
    titleSlug: slugify(description.name),
    typeCode: item.itemShortId ? 'IT' : 'SE',
    shortId: item.itemShortId || item.seanceShortId
  }
}

export function prepareItemPictureUrl(params: {
  resourceReferenceId: string
  item: Pick<Item, 'translations' | 'itemShortId' | 'seanceShortId'>
  size?: typeof IMAGE_SIZES[keyof typeof IMAGE_SIZES]['code']
}) {
  const itemSourceType = getItemSourceType(params.item)
  if (!itemSourceType) return null
  const baseUrl = getBaseResourceUrl('pictures')
  const resourceKey = [itemSourceType.typeCode, itemSourceType.shortId, params.resourceReferenceId, params.size ?? IMAGE_SIZES.LARGE.code].join(':')
  return `${baseUrl}/${itemSourceType.titleSlug}(${resourceKey}).jpg`
}
