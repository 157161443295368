import { AccessRights } from '@auctelia/access-manager'
import { useStorage as useVStorage } from '@vueuse/core'
import type { AuthCredentials } from '#types/entities/auth'
import type { RouteLocationNormalized } from '#vue-router'

export const useUserStore = defineStore('user', () => {
  const route = useRoute()
  const { $api, $i18n } = useNuxtApp()
  const COOKIE_MAX_AGE_REMEMBER_ME = 60 * 60 * 24 * 180 // 6 months
  const enableAccessControl = useVStorage('enable-access-control', false, localStorage)

  const beaconToken = useCookie('beaconToken')
  const organisationShortId = ref<string>('')
  const userAccess = ref<AccessRights>()

  const {
    data: user,
    refresh: refreshUser,
    pending: isAuthLoading
  } = useAsyncState(
    () => (beaconToken.value ? $api.auth.loggedUser() : null),
    {
      onError: () => signOut({ local: true }),
      onData: data => data && (organisationShortId.value = data.activeOrganisationShortId ?? data.organisationShortId!)
    }
  )

  const {
    data: activeOrganisation,
    refresh: refreshOrganisation,
    pending: loadingOrganisation
  } = useAsyncState(
    () => organisationShortId.value
      ? $api.organisations.getOrganisationById(organisationShortId.value)
      : null,
    { watch: [organisationShortId] }
  )

  const isOrgAdmin = computed(
    () => user?.value?.accessRights?.isOrgAdmin ?? false
  )

  const accessKeyTree = computed(() => createAccessTree(user.value?.sessionAccessRights ?? []))

  async function signIn(credentials: AuthCredentials & { rememberMe: boolean }) {
    const { success, error, data } = await withErrorHandling(() => $api.auth.login(credentials))
    if (!success) return { success: false, error }

    if (success) {
      if (data.status === 'TO_VALIDATE') {
        navigateTo('/auth/register/completed')
        return { success: true }
      }
      const { token, ...userData } = data
      beaconToken.value = token
      user.value = userData
      organisationShortId.value = userData.activeOrganisationShortId ?? userData.organisationShortId
      const maxAge = credentials.rememberMe ? COOKIE_MAX_AGE_REMEMBER_ME : undefined
      useCookie('beaconToken', { maxAge }).value = token

      nextTick(() => {
        const redirectUrl = route.query.redirect?.toString() ?? route.query?.callback?.toString()
        if (!redirectUrl)
          return navigateTo({ name: 'dashboard' })

        const { url, external } = buildRedirectUrlWithToken(token, redirectUrl)
        return navigateTo(url, { external })
      })

      return { success: true }
    }
  }

  async function setActiveSession(
    { token, ...data }: NonNullable<typeof user.value> & { token: string },
    params?: { redirect?: boolean, action?: string }
  ) {
    beaconToken.value = token
    user.value = data
    organisationShortId.value = data.activeOrganisationShortId ?? data.organisationShortId
    if (params?.redirect)
      nextTick(() => {
        const redirect = route.query.redirect?.toString()
        navigateTo(redirect ? decodeURIComponent(redirect) : '/')
      })
    else {
      const redirect = await validatePageAuth(route)
      if (redirect) navigateTo({
        ...redirect,
        query: {
          ...(redirect.query ?? {}),
          ...(params?.action ? { action: params.action } : {})
        }
      })
    }
  }

  async function signOut(params?: { local?: boolean }) {
    try {
      if (!params?.local) {
        await withoutGlobalError(await $api.auth.logout)
        await sleep(250)
      }
      beaconToken.value = null
      user.value = null
      organisationShortId.value = ''
      nextTick(() => navigateTo('/auth/login'))
    } catch {
      return navigateTo('/auth/login')
    }
  }

  async function switchOrganisation() {
    const { $messageApi, $formApi, $i18n } = useNuxtApp()
    const { isCompleted, formData } = await $formApi.createForm(
      organisationSwitchSchema(),
      { input: { organisationShortId: organisationShortId.value } }
    )

    if (!isCompleted) return

    if (formData.selectedOrganisation === organisationShortId.value) {
      $messageApi.warning($i18n.t('messages.organisationAlreadySelected'))
      return
    }

    const session = await actionNotification({
      indicator: 'app-spinner',
      handler: () => $api.auth.changeOrganisation(formData.selectedOrganisation)
    })

    if (session) setActiveSession(session)
  }

  async function waitUntilAuthLoaded() {
    if (isAuthLoading.value)
      while (isAuthLoading.value) {
        console.log('WAITING FOR AUTH...')
        await sleep(50)
      }

    await nextTick()
  }

  async function validatePageAuth(to: RouteLocationNormalized) {
    if (to.name === 'healthcheck') return
    if (to.name === 'auth-logout') return

    if (to.meta.auth ?? true) {
      await waitUntilAuthLoaded()

      if (!beaconToken.value)
        return appLink({
          name: 'auth-login',
          query: { redirect: encodeURIComponent(to.fullPath) }
        })

      if (
        user.value
        && user.value?.status !== 'COMPLETED'
        && to.name !== 'profile-onboarding'
      )
        return appLink({ name: 'profile-onboarding' })

      if (
        user.value?.status === 'COMPLETED'
        && to.name === 'profile-onboarding'
      ) return appLink({ name: 'dashboard' })

      if (to.meta.accessKeys?.length && !hasAccess(to.meta.accessKeys)) {
        // ON PAGE LOAD, REACTIVE APIs AREN'T LOADED YET
        // WE NEED TO DEFER THE MESSAGE RENDERING TO SOLVE THE PROBLEM
        setTimeout(() => {
          const { $messageApi, $i18n } = useNuxtApp()
          $messageApi.error($i18n.t('messages.accessDenied'))
        }, 100)
        return appLink({ name: 'dashboard' })
      }
    } else if (beaconToken.value)
      return appLink({ name: 'dashboard' })
  }

  watch(
    () => user.value,
    (user) => {
      if (user && user.status !== 'COMPLETED')
        navigateTo('/profile/onboarding')
    }
  )

  watch(
    () => user.value,
    user => user && (userAccess.value = new AccessRights(user))
  )

  watch(() => enableAccessControl.value, async (enabled) => {
    if (!enabled) return
    const redirect = await validatePageAuth(route)
    if (redirect) navigateTo(redirect)
  })

  watch(
    () => activeOrganisation.value?.organisationShortId,
    (id, old) => {
      if (!old || !id || id === old) return
      const { $messageApi } = useNuxtApp()
      $messageApi.success($i18n.t('messages.switchOrganisationSuccess', { name: activeOrganisation.value?.name }))
    })

  return {
    user,
    userAccess,
    enableAccessControl,
    beaconToken,
    organisationShortId,
    activeOrganisation,
    loadingOrganisation,
    isAuthLoading,
    isOrgAdmin,
    accessKeyTree,
    switchOrganisation,
    setActiveSession,
    refreshUser,
    refreshOrganisation,
    signIn,
    signOut,
    waitUntilAuthLoaded,
    validatePageAuth
  }
})
