import { defineTheme } from '../utils/theme'

export const AgorastoreMainTheme = defineTheme({
  app: {
    theme: 'agora-main',
    name: 'AgoraStore'
  },
  slots: {
    navigation: {
      hover: 'secondary',
      active: 'secondary'
    },
    header: {
      border: 'secondary',
      sellButton: {
        background: 'secondary',
        text: '#000'
      }
    },
    wizard: {
      progressBar: 'secondary'
    },
    itemCard: {
      bidButton: {
        background: 'secondary',
        text: '#000'
      }
    }
  },
  logo: {
    small: '/themes/agora-main/logo-small.svg',
    large: '/themes/agora-main/logo.svg',
    favicon: '/themes/agora-main/favicon.ico'
  },
  colors: {
    primary: '#2B4D93',
    secondary: '#FDBF62'
  }
})

// export const AgorastoreImmoTheme: ThemeConfig = {
//   app: {
//     theme: 'agora-immo',
//     name: 'AgoraStore'
//   },
//   logo: {
//     small: '/themes/agora-immo/logo-small.svg',
//     large: '/themes/agora-immo/logo.svg',
//     favicon: '/themes/agora-main/favicon.ico'
//   },
//   colors: {
//     primary: '#9D1E52',
//     secondary: '#9CAFBE'
//   },
//   common: {
//     fontFamily: 'Plus Jakarta Sans'
//   }
// }

export const AucteliaTheme = defineTheme({
  app: {
    theme: 'auctelia',
    name: 'Auctelia'
  },
  slots: {
    navigation: {
      hover: 'primary',
      active: 'primary'
    },
    header: {
      border: 'primary',
      sellButton: {
        background: 'secondary',
        text: '#fff'
      }
    },
    wizard: {
      progressBar: 'primary'
    },
    itemCard: {
      bidButton: {
        background: 'primary',
        text: '#fff'
      }
    }
  },
  logo: {
    small: '/themes/auctelia/logo-small.svg',
    large: '/themes/auctelia/logo.svg',
    favicon: '/themes/auctelia/favicon.ico'
  },
  colors: {
    primary: '#e5703a',
    secondary: '#00b5e2',
    info: '#2196f3',
    success: '#4caf50',
    warning: '#ffc107',
    error: '#b71c1c'
  }
})

export const APP_THEMES = {
  agora: AgorastoreMainTheme,
  auctelia: AucteliaTheme
  // agoraImmo: AgorastoreImmoTheme
} as const
