import { Loader } from '@googlemaps/js-api-loader'

export default defineNuxtPlugin(async () => {
  const config = useRuntimeConfig()
  const loader = new Loader({
    apiKey: config.public.googleApiKey,
    libraries: ['places']
  })
  const places = await loader.importLibrary('places')
  return {
    provide: {
      places
    }
  }
})
