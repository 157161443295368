import type { AxiosError } from 'axios'
import { createApiClient } from '#api-client'
import type { ErrorResponse } from '#types/system/api'

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()
  const beaconToken = useCookie('beaconToken')
  const { ApiClient, HttpClient } = createApiClient({
    baseUrl: config.public.apiUrl,
    validate: config.public.env === 'dev' ? 'none' : 'none',
    transform: false
  })

  HttpClient.use({
    name: 'AUTH_HEADERS',
    async request(_, config) {
      await sleep(10)
      return Promise.resolve({
        ...config,
        headers: {
          ...config.headers,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          ...(beaconToken.value
            ? { Authorization: `Bearer ${beaconToken.value}` }
            : {})
        }
      })
    }
  })

  HttpClient.use({
    name: 'API_ERROR_HANDLER',
    error(_, __, _error) {
      const { $messageApi, $i18n } = useNuxtApp()
      const appUi = useAppUi()
      const error = _error as AxiosError<ErrorResponse>
      if (appUi.globalErrorsEnabled)
        $messageApi.error(
          $i18n.t(`apiErrorMessages.${error.response?.data.meta?.error}`)
        )

      throw error
    }
  })

  return {
    provide: {
      api: ApiClient
    }
  }
})
