export const appBuildAssetsDir = "_nuxt_1315"

export const appPageTransition = {"name":"slide-fade-reverse","mode":"out-in"}

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/themes/agora-main/favicon.ico"}],"style":[],"script":[{"children":"\n            window.markerConfig = {\n              destination: \"65d600b86a146a75e55734d1\",\n              source: \"snippet\"\n            };\n            !function(e,r,a){\n              if(!e.__Marker){\n                e.__Marker={};\n                var t=[],n={__cs:t};\n                [\"show\",\"hide\",\"isVisible\",\"capture\",\"cancelCapture\",\"unload\",\"reload\",\"isExtensionInstalled\",\"setReporter\",\"clearReporter\",\"setCustomData\",\"on\",\"off\"].forEach(function(e){\n                  n[e]=function(){\n                    var r=Array.prototype.slice.call(arguments);\n                    r.unshift(e),t.push(r)\n                  }\n                });\n                e.Marker=n;\n                var s=r.createElement(\"script\");\n                s.async=1,\n                s.src=\"https://edge.marker.io/latest/shim.js\";\n                var i=r.getElementsByTagName(\"script\")[0];\n                i.parentNode.insertBefore(s,i)\n              }\n            }(window,document);\n          "}],"noscript":[]}

export const appBaseURL = "/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const appSpaLoaderTag = "div"

export const appSpaLoaderAttrs = {"id":"__nuxt-loader"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"deep":false}

export const resetAsyncDataToUndefined = false

export const nuxtDefaultErrorValue = undefined

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false

export const spaLoadingTemplateOutside = true