import payload_plugin_qLmFnukI99 from "/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import ui_config_xWDGSemv9f from "/app/node_modules/@agorastore/shared-ui/dist/runtime/plugins/ui-config.js";
import route_progress_Kx1xM5Kipf from "/app/node_modules/@agorastore/shared-ui/dist/runtime/plugins/route-progress.js";
import page_context_eXMJ6OJIt8 from "/app/node_modules/@agorastore/shared-ui/dist/runtime/plugins/page-context.js";
import i18n_Os43gPxLvI from "/app/node_modules/@agorastore/shared-ui/dist/runtime/plugins/i18n.js";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_WLsn00x1qh from "/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import access_key_FWVdRe3bLE from "/app/app/plugins/access-key.ts";
import api_client_Op9s75ObdX from "/app/app/plugins/api-client.ts";
import cdn_rmTAVMfqe6 from "/app/app/plugins/cdn.ts";
import google_places_ob7WPE58MF from "/app/app/plugins/google-places.ts";
import i18n_KYRD8MIJaU from "/app/app/plugins/i18n.ts";
import recaptcha_hQeEpUX7gn from "/app/app/plugins/recaptcha.ts";
import redirections_hNBfead6ZN from "/app/app/plugins/redirections.ts";
import sentry_h2FqLeojBn from "/app/app/plugins/sentry.ts";
import shop_config_cdj1PerWJv from "/app/app/plugins/shop-config.ts";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  ui_config_xWDGSemv9f,
  route_progress_Kx1xM5Kipf,
  page_context_eXMJ6OJIt8,
  i18n_Os43gPxLvI,
  unocss_MzCDxu9LMj,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_WLsn00x1qh,
  access_key_FWVdRe3bLE,
  api_client_Op9s75ObdX,
  cdn_rmTAVMfqe6,
  google_places_ob7WPE58MF,
  i18n_KYRD8MIJaU,
  recaptcha_hQeEpUX7gn,
  redirections_hNBfead6ZN,
  sentry_h2FqLeojBn,
  shop_config_cdj1PerWJv
]