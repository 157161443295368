import { helpers } from '@vuelidate/validators'
import { Icon, NuxtLink } from '#components'

export function loginFormSchema() {
  const { $i18nValidators, $i18n } = useNuxtApp()
  return defineFormSchema({
    testId: 'login',
    layout: {
      gridSize: 1,
      fieldSize: 1
    },
    fields: [
      {
        label: () => $i18n.t('labels.email'),
        key: 'username',
        type: 'text',
        required: true,
        watch: ({ api }) => api.clearCustomError('password'),
        validators: {
          email: $i18nValidators.email
        }
      },
      {
        label: () => $i18n.t('labels.password'),
        key: 'password',
        type: 'password',
        required: true,
        watch: ({ api }) => api.clearCustomError('username')
      },
      {
        key: 'rememberMe',
        type: 'checkbox',
        label: () => <div class="w-full">{$i18n.t('labels.rememberMe')}</div>,
        labelWidth: 'fill',
        labelExtra: () => (
          <div class="w-full" data-testid="form:login#field:rememberMe#labelextra">
            <NuxtLink to="/auth/password/forgot">
              {$i18n.t('actions.resetPassword')}
            </NuxtLink>
          </div>
        )
      }
    ],
    actions: [
      { key: 'submit', label: () => $i18n.t('actions.login'), width: 'fill', size: 'large' }
    ]
  })
}

export function forgotPasswordFormSchema() {
  const { $i18nValidators, $i18n } = useNuxtApp()
  return defineFormSchema({
    testId: 'forgot-password',
    layout: { gridSize: 1 },
    actions: [
      { key: 'submit', label: () => $i18n.t('actions.resetPasswordSubmit'), width: 'fill', size: 'large' }
    ],
    fields: [
      {
        label: () => $i18n.t('labels.email'),
        key: 'email',
        type: 'text',
        required: true,
        validators: {
          email: $i18nValidators.email
        }
      }
    ]
  })
}

export function resetPasswordFormSchema() {
  const { $i18n, $i18nValidators } = useNuxtApp()
  return defineFormSchema({
    testId: 'reset-password',
    layout: { gridSize: 1 },
    actions: [
      {
        key: 'submit',
        label: () => $i18n.t('actions.save'),
        width: 'fill',
        size: 'large'
      }
    ],
    fields: [
      {
        label: () => $i18n.t('labels.newPassword'),
        key: 'password',
        type: 'password',
        required: true,
        props: { showFeedback: true },
        validators: {
          passwordStrength: $i18nValidators.withI18nMessage(
            helpers.regex(passwordStrengthRegex)
          )
        }
      },
      {
        label: () => $i18n.t('labels.confirmPassword'),
        key: 'confirmPassword',
        type: 'password',
        required: true,
        dependencies: ['password'],
        validators: ({ deps }) => ({
          passwordMatch: $i18nValidators.sameAs(deps.password)
        })
      }
    ]
  })
}

export function organisationSwitchSchema() {
  const { $i18n } = useNuxtApp()
  const userStore = useUserStore()
  return defineFormSchema({
    testId: 'organisation-switch',
    title: () => $i18n.t('actions.chooseOrganisation'),
    modal: { maxWidth: '500px' },
    actions: [{ key: 'submit', label: () => $i18n.t('actions.confirm') }],
    fields: [
      {
        key: 'search',
        type: 'text',
        placeholder: $i18n.t('labels.organisation'),
        props: { suffix: () => <Icon name="mdi:search" /> }
      },
      {
        key: 'selectedOrganisation',
        type: 'radio-card',
        size: 8,
        dependencies: ['search'],
        default: userStore.organisationShortId,
        options: {
          clearOnInvalid: false,
          source: ({ deps }) => {
            const searchTerm = deps.search?.toLowerCase()
            const availableOrganisations = (userStore.user?.availableOrganisations ?? []).filter(Boolean)

            return availableOrganisations
              .filter(org => !searchTerm || org.name.toLocaleLowerCase().includes(searchTerm))
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(org => ({
                label: org.name,
                value: org.organisationShortId
              }))
          }
        }
      }
    ]
  })
}

export function registerUserFormSchema() {
  const { $i18n, $i18nValidators } = useNuxtApp()
  return defineFormSchema({
    testId: 'register',
    layout: { fieldSize: 8 },
    actions: [
      {
        key: 'submit',
        label: () => $i18n.t('actions.createAccount'),
        width: 'fill',
        size: 'large'
      }
    ],
    fields: [
      {
        key: 'title',
        label: () => $i18n.t('labels.gender'),
        type: 'radio',
        options: (['MR', 'MRS'] as const).map(value => ({
          label: () => $i18n.t(`values.gender.${value}`),
          value
        })),
        default: 'MR',
        required: true,
        size: 8
      },
      {
        key: 'lastName',
        type: 'text',
        label: () => $i18n.t('labels.lastName'),
        placeholder: () => $i18n.t('placeholders.lastName'),
        required: true,
        size: '8 md:4'
      },
      {
        key: 'firstName',
        type: 'text',
        label: () => $i18n.t('labels.firstName'),
        placeholder: () => $i18n.t('placeholders.firstName'),
        required: true,
        size: '8 md:4'
      },
      {
        key: 'email',
        type: 'text',
        label: () => $i18n.t('labels.email'),
        placeholder: () => $i18n.t('placeholders.email'),
        required: true,
        transform: (email: string) => email.replace(/\s/g, '').toLowerCase(),
        validators: {
          email: $i18nValidators.email
        }
      },
      {
        key: 'password',
        type: 'password',
        label: () => $i18n.t('labels.password'),
        placeholder: () => $i18n.t('placeholders.password'),
        required: true,
        props: { showFeedback: true },
        validators: {
          passwordStrength: $i18nValidators.withI18nMessage(
            helpers.regex(passwordStrengthRegex)
          )
        }
      },
      {
        key: 'confirmPassword',
        type: 'password',
        label: () => $i18n.t('labels.confirmPassword'),
        placeholder: () => $i18n.t('placeholders.confirmPassword'),
        required: true,
        dependencies: ['password'],
        validators: ({ deps }) => ({
          passwordMatch: $i18nValidators.sameAs(deps.password)
        })
      },
      {
        key: 'terms',
        type: 'checkbox',
        label: () => (
          <div class="w-full text-xs">
            <span>{$i18n.t('labels.acceptTerms')}</span>
            <a href="https://policies.google.com/terms" rel="noreferrer noopener" target="_blank">{ $i18n.t('labels.terms') }</a>
            <span>{$i18n.t('labels.and')}</span>
            <a href="https://policies.google.com/privacy" rel="noreferrer noopener" target="_blank">{ $i18n.t('labels.privacy') }</a>
            <p class="m-0">{$i18n.t('labels.reCaptcha')}</p>
          </div>
        ),
        required: true,
        requiredMessage: () => $i18n.t('ui.form.validators.requiredUsageConditions')
      }
    ]
  })
}
