import type { FeatureFlagKey } from '@/utils/shop'

export default defineNuxtPlugin((app) => {
  const appUi = useAppUi()
  const dataStore = useDataStore()
  app.hook('app:created', () => {
    const hostname = getCurrentHostname()
    const shop = getShopInfo(hostname)
    dataStore.setCurrentShopId(shop.shopShortId)
    if (APP_THEMES[shop.theme]) appUi.setThemeConfig(APP_THEMES[shop.theme])
  })

  return {
    provide: {
      hasFlag
    }
  }
})

function getCurrentHostname() {
  if (!import.meta.server) return window.location.hostname
  const reqHost = useRequestHeaders(['host'])
  return reqHost.host || ''
}

function getActiveFeatureKeys() {
  const dataStore = useDataStore()
  const shop = dataStore.currentShop
  if (!shop?.meta?.features)
    return []
  return shop.meta.features
    .filter(feature => feature.active)
    .map(feature => feature.flag)
}

function hasFlag(flag: FeatureFlagKey | FeatureFlagKey[]) {
  const activeFlags = getActiveFeatureKeys()
  if (Array.isArray(flag))
    return flag.some(f => activeFlags.includes(f))

  return activeFlags.includes(flag)
}
