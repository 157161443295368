import { defineRedirects } from '~/utils/router'

export const ROUTER_REDIRECTS = defineRedirects([
  { from: '/:locale(fr|en|nl|de|pl)?/login', to: '/auth/login', callback: 'immediate' },
  { from: '/:locale(fr|en|nl|de|pl)?/register', to: '/auth/register', callback: 'deferred' },
  { from: '/:locale(fr|en|nl|de|pl)?/profile', to: '/', callback: 'deferred' },
  { from: '/:locale(fr|en|nl|de|pl)?/profile/account', to: '/profile/information', callback: 'deferred' },
  { from: '/seller/:locale(fr|en|nl|de|pl)?/profile/questions', to: '/seller/questions', callback: 'deferred' },
  { from: '/buyer/:locale(fr|en|nl|de|pl)?/profile/questions', to: '/buyer/questions', callback: 'deferred' },
  { from: '/:locale(fr|en|nl|de|pl)?/profile/saved-searches', to: '/buyer/saved-searches', callback: 'deferred' },
  { from: '/:locale(fr|en|nl|de|pl)?/profile/watchlist', to: '/buyer/watch-lists', callback: 'deferred' },
  { from: '/organisation/:locale(fr|en|nl|de|pl)?/profile/documents', to: '/organisation/documents', callback: 'deferred' },
  { from: '/profile/:locale(fr|en|nl|de|pl)?/profile/documents', to: '/profile/documents', callback: 'deferred' },
  { from: '/validate', to: '/auth/validate', callback: 'deferred' },
  { from: '/reset-password', to: '/auth/password/reset', callback: 'deferred' },
  { from: '/seller/:locale(fr|en|nl|de|pl)?/profile/sales', to: '/seller/items', callback: 'deferred' },
  { from: '/buyer/:locale(fr|en|nl|de|pl)?/profile/purchases', to: '/buyer/items', callback: 'deferred' },
  { from: '/:locale(fr|en|nl|de|pl)?/profile/invoices', to: '/seller/invoices/sales', callback: 'deferred' }
])
